import { Location, NavigateFunction } from 'react-router-dom';
import scrollToPage from '../../utils/ScrollToLink';
import smallLogo from '../../assets/imgs/nikolica_logo_dark.svg';
import {
  FOOTER,
  PROPERTY_NAME,
  separatorColoredDown,
} from '../../utils/Constants';
import { getLocales } from '../../locales/Locale';
import Variables from '../../style/variables.scss';

const Footer = ({
  location,
  navigate,
}: {
  location: Location;
  navigate: NavigateFunction;
}) => {
  const { lang } = getLocales();

  return (
    <div id={FOOTER} className='footer'>
      {separatorColoredDown(Variables.secondaryColor)}
      <div className='footer_container container'>
        <div
          className='footer_container_logo'
          onClick={() => scrollToPage('home', location, navigate)}
        >
          <img src={smallLogo} alt={PROPERTY_NAME + ' small logo'} />
        </div>
        <div className='footer_container_contacts'>
          <div className='footer_container_contacts_contact'>
            <p className='uppercase'>
              <b>{lang.footer.nikolica}</b>
            </p>
            <p>
              <a
                href='https://maps.app.goo.gl/zg2Mvdx4eHzCVGFfA'
                target='_blank'
                rel='noreferrer'
                className='opacity_transition_low'
              >
                {lang.footer.radovic}
              </a>
            </p>
            <p>20244 Dingač, Croatia</p>
            <p>
              <a href='tel:0038598571226' className='opacity_transition_low'>
                +385 98 571 226
              </a>
            </p>
            <p>
              <a
                href='mailto:reservations@dingac.com'
                className='opacity_transition_low'
              >
                reservations@dingac.com
              </a>
            </p>
          </div>
        </div>
        <div className='footer_container_bottom'>
          <p>
            Copyright © {lang.footer.nikolica} {new Date().getFullYear()}.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
