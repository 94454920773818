import { useEffect, useState } from 'react';
import { NavigateFunction, Location } from 'react-router-dom';
import logo from '../../assets/imgs/nikolica_logo_dark_small.svg';
import scrollToPage from '../../utils/ScrollToLink';
import ScrollDirection from '../../utils/ScrollDirection';
import Variables from '../../style/variables.scss';
import {
  ACCOMMODATION,
  BOOK_NOW_LINK,
  DISCOVER,
  FOOTER,
  GALLERY,
  HOME,
  PROPERTY_NAME,
} from '../../utils/Constants';
import LanguageMenu from '../languageMenu/LanguageMenu';
import { getLocales } from '../../locales/Locale';

const Navbar = ({
  location,
  navigate,
}: {
  location: Location;
  navigate: NavigateFunction;
}) => {
  const { lang } = getLocales();
  const direction = ScrollDirection();

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  let header: HTMLElement | null = document.getElementById('nav_container');
  let headerBackground: HTMLElement | null =
    document.getElementById('nav_background');

  const handleLink = (target: string) => {
    setOpenMenu(!openMenu);
    scrollToPage(target, location, navigate);

    setTimeout(() => {
      if (header !== null && headerBackground !== null && window.scrollY > 96) {
        headerBackground.style.top = '-6rem';
        header.style.top = '-6rem';
      }
    }, 1100);
  };

  const handelHeaderAppearance = () => {
    if (header !== null && headerBackground !== null) {
      if (direction === 'up') {
        headerBackground.style.top = '0';
        header.style.top = '0';

        if (window.scrollY < 96) {
          headerBackground.style.background = 'transparent';
        }
      } else if (direction === 'down') {
        headerBackground.style.top = '-6rem';
        header.style.top = '-6rem';

        if (window.scrollY >= 96) {
          headerBackground.style.background = Variables.complementaryColor;
        }
      }
    }
  };

  window.onscroll = () => {
    handelHeaderAppearance();
  };

  useEffect(() => {
    if (openMenu) {
      document.body.classList.add('lock_scroll');
      document.getElementById('nav_menu')?.classList.add('open_sidemenu');
    } else {
      document.body.classList.remove('lock_scroll');
      document.getElementById('nav_menu')?.classList.remove('open_sidemenu');
    }
  }, [openMenu]);

  return (
    <div className='nav_wrapper'>
      <div id='nav_container'>
        <div className='nav_menu'>
          <div className='nav_menu_burger'>
            <input
              className='nav_menu_burger_cheeckbox'
              type='checkbox'
              onChange={() => setOpenMenu(!openMenu)}
              checked={openMenu}
              aria-label='Burger'
            />
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div className='nav_logo'>
          <img
            src={logo}
            alt={PROPERTY_NAME + ' logo'}
            id='nav_logo'
            className='pointer'
            onClick={() => {
              scrollToPage('home', location, navigate);
              setOpenMenu(false);
            }}
          />
        </div>
        <div className='nav_book'>
          <LanguageMenu />
          <a
            href={BOOK_NOW_LINK}
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            <div>{lang.bookNow.toLocaleUpperCase()}</div>
          </a>
        </div>
      </div>
      <div id='nav_background'></div>
      <div id='nav_menu'>
        <div className='nav_menu_list'>
          <div className='nav_menu_list_links'>
            <div
              className='opacity_transition_high lowercase'
              onClick={() => handleLink(HOME)}
            >
              {lang.home}
            </div>
            <div
              className='opacity_transition_high lowercase'
              onClick={() => handleLink(ACCOMMODATION)}
            >
              {lang.accommodation.title}
            </div>
            <div
              className='opacity_transition_high lowercase'
              onClick={() => handleLink(DISCOVER)}
            >
              {lang.discover.title}
            </div>
            <div
              className='opacity_transition_high lowercase'
              onClick={() => handleLink(GALLERY)}
            >
              {lang.gallery.title}
            </div>
            <div
              className='opacity_transition_high lowercase'
              onClick={() => handleLink(FOOTER)}
            >
              {lang.footer.title}
            </div>
            <div>
              <LanguageMenu />
            </div>
          </div>
          <div className='nav_menu_list_image'></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
