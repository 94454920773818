import { title } from 'process';

const en = {
  title: 'Nikolica Apartments',
  home: 'home',
  showMore: 'Show More',
  bookNow: 'Book Now',
  about: {
    title: 'Nikolica Apartments',
    p1: `In the heart of Croatia's famed wine region, Nikolica Apartments provide guests with a variety of ways to embrace all that Pelješac has to offer.`,
    p2: 'Perched next to the sea, these apartments serve as a great jumping-off point for outdoor enthusiasts eager to swim, hike, cycle and more in the surrounding area.',
    p3: 'Inside every apartment, guests will find modern furnishings, bathroom with a shower, fully equipped kitchen, air conditioning, SAT TV and terrace with a breathtaking view.',
    p4: 'Additional perks include a parking place, grill as well as an outside pool and loungers.',
  },
  services: {
    title: 'Services',
    parking: 'On-site parking',
    privacy: 'Hosts do not live on this property',
    beach: 'Nearest beach: 20 m',
    wifi: 'Free WiFi',
    restaurant: 'Nearest restaurant: 40 m',
    towels: 'Change of towels: once a week',
    tv: 'Satellite TV',
    traffic: 'Nearest traffic road: 4km',
    smoking: 'Smoking is not allowed',
    ac: 'Air conditioning',
    atm: 'Nearest ATM: 4km',
    pets: 'No pets',
  },
  accommodation: {
    title: 'Apartments',
    p1: 'Nestled right above the beach, this tranquil property charms guests with its beautiful views of the Adriatic Sea and surrounding Mediterranean vegetation which provides peace and quiet for a relaxing holiday by the sea.',
  },
  discover: {
    title: 'Staying At Pelješac',
    peljesac: {
      p1: `The Pelješac peninsula is halfway between two Adriatic pearls, Split and Dubrovnik. The second-largest peninsula in Dalmatia enjoys a splendid Mediterranean climate, which has made it an ideal site for vineyards and winemaking.`,
      p2: `Pelješac is also famous for its salt pans, which many depended on for centuries, including Dubrovnik and its celebrated Republic. The ancient defensive rampart completes a trilogy of Pelješac symbols. The natives like to call their peninsula "nature in its primeval form."`,
      p3: `Pelješac Riviera is perhaps the warmest area of the Croatian littoral, an aromatic garden filled with almonds, nuts, figs, pomegranates, citrus fruit and kiwis. The clear sea which makes Pelješac an attractive tourist destination finds its way to the dinner tables of the area's visitors.`,
      p4: `Amongst the numerous fishes, crab and shellfish species, special praise is reserved for the oysters. Mali Ston is notable for growing and preparing this delicacy which is, aside from its extraordinary flavor and nutritive values, said to have also aphrodisiac qualities.`,
    },
    dingac: {
      p1: `Dingač represents an ideal place for all those who enjoy natural beauties of the Mediterranean. It's a favorite resort for family vacationers from all around Europe.`,
      p2: `Dingač and its surroundings are known for their wine growing tradition of grapes for Dingač, one of Croatia's best red wines, which are grown on the 45 degrees slopes of the same name. This excellent wine has a strong taste due to the warm climate with plenty of sun (2,800 hours per year). For this reason and in order to enjoy the tranquility, many tourists come to Dingač Borak.`,
      p3: `Grocery store, post office and a gas station are 3 km away, while the sea is 10 to 20 m from the private houses. Enjoy the sun on the beautiful sandy and pebble beaches as well as on the rocky swimming areas in front of private houses.`,
      p4: `Pelješac is a fascinating destination for half-day or whole-day excursions. It has picturesque little towns and villages, a rich cultural and historical heritage, beautifully cultivated vineyards (especially those precariously placed on the slopes of Dingač), and many monuments to human endeavor and skillfulness.`,
    },
    potomje: {
      p1: `Potomje is an old village located in the central part of Pelješac at 300 m above sea level, 2.5 km from the sea, 15 km southeast of Orebić.`,
      p2: `Because of easier access to the south, a 400 m long tunnel was built from Potomje, which is today an extraordinary tourist attraction.`,
      p3: `Located in the western part of the wine-growing areas Dingač, Potomje is the manufacturing hub of the best red wines Croatian (Dingač, Postup and Plavac).`,
    },
    orebic: {
      p1: `Orebić is a well-known tourist center of the Pelješac peninsula and a scenic 20 km drive away from Dingač, offering restaurants, bars, tennis courts and discotheques.`,
      p2: `For all those who prefer a more active vacation, Pelješac offers a variety of recreational opportunities in the sea: from swimming, surfing, water skiing, rowing and sailing, underwater diving exploration of various marine flora and fauna and archaeological sites.`,
      p3: `Other recreation opportunities are also diverse: walking paths through the countryside away from the cars, cycling and hiking, especially on the highest peak of Pelješac - St. Elias (961 m).`,
    },
  },
  gallery: {
    title: 'Gallery',
  },
  location: {
    title: 'Location',
  },
  footer: {
    title: 'Contact us',
    nikolica: 'Nikolica Apartments',
    radovic: 'Radović family',
  },
};

export default en;
