import { scroller } from 'react-scroll';
import { Location, NavigateFunction } from 'react-router-dom';
import { FOOTER } from './Constants';

const scrollTarget = (target: string, ease: boolean) => {
  const smooth = {
    spy: true,
    smooth: true,
    duration: 1000,
  };

  const instant = {
    spy: true,
    smooth: false,
  };

  if (ease) {
    scroller.scrollTo(target, smooth);
  } else {
    scroller.scrollTo(target, instant);
  }
};

const scrollToPage = async (
  target: string,
  location: Location,
  navigate: NavigateFunction,
  page?: boolean
) => {
  if (page) {
    await navigate(`/${target}`);
    scrollTarget(target, false);
  } else if (location.pathname !== '/' && target !== FOOTER) {
    await navigate('/');
    scrollTarget(target, false);
  } else {
    scrollTarget(target, true);
  }
};

export default scrollToPage;
