import { useEffect, useState } from 'react';

export enum Direction {
  up = 'up',
  down = 'down',
}

const ScrollDirection = () => {
  const [direction, setDirection] = useState<Direction>(Direction.up);
  let oldScrollY = 0;

  const controlDirection = () => {
    if (window.scrollY > oldScrollY && window.scrollY - oldScrollY > 10) {
      setDirection(Direction.down);
    } else if (
      window.scrollY < oldScrollY &&
      oldScrollY - window.scrollY > 10
    ) {
      setDirection(Direction.up);
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', controlDirection);
    return () => {
      window.removeEventListener('scroll', controlDirection);
    };
  }, []);

  return direction;
};

export default ScrollDirection;
