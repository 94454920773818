import { getLanguage, Language, setLanguage } from '../../locales/Locale';

export default function LanguageMenu() {
  const currentLanguage = getLanguage();

  const updateLanguage = (event: any) => {
    setLanguage(event.target.value);
    window.location.reload();
  };

  return (
    <select
      className='header_lang opacity_transition_low'
      value={currentLanguage}
      onChange={updateLanguage}
      aria-label='Language'
    >
      <option value={Language.croatian}>HR</option>
      <option value={Language.english}>EN</option>
    </select>
  );
}
