import AOS from 'aos';
import { RouterProvider } from 'react-router-dom';
import { useEffect } from 'react';
import { getLanguageFromURL } from './locales/Locale';
import router from './routes';

const App: React.FC = () => {
  let doc: HTMLElement = document.documentElement;

  const setHight = () => {
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  window.addEventListener('resize', setHight);

  useEffect(() => {
    AOS.init({
      duration: 700,
      easing: 'ease-out-cubic',
      offset: 100,
    });

    setHight();
    getLanguageFromURL();
  }, []);

  return <RouterProvider router={router} />;
};

export default App;
