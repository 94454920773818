import { snakeCase, upperFirst } from 'lodash';
import en from './languages/en';
import hr from './languages/hr';

export enum Language {
  english = 'en',
  croatian = 'hr',
}

const locales = { en, hr };
const languageSessionKey = 'language';

export const defaultLanguage: Locale.Languages = Language.croatian;

export function getLocales() {
  const currentLanguage = getLanguage();
  const lang = locales[currentLanguage];
  function translate(key: any): string {
    const translation = (lang as any)[key];
    if (translation) return translation;
    else if (key && key.indexOf(' ') < 0)
      return upperFirst(snakeCase(key).replace(/_/g, ' '));
    else return key;
  }

  return {
    lang,
    currentLanguage,
    translate,
  };
}

export function getLanguage() {
  return (
    (localStorage.getItem(languageSessionKey) as Locale.Languages) ||
    defaultLanguage
  );
}

export function setLanguage(language?: Locale.Languages) {
  localStorage.setItem(languageSessionKey, language || defaultLanguage);
  updateLanguageInURL(language || defaultLanguage);
}

export function updateLanguageInURL(language: Locale.Languages) {
  const url = new URL(window.location.href);
  url.searchParams.set('lang', language);
  window.history.replaceState({}, '', url.toString());
}

export function getLanguageFromURL() {
  const url = new URL(window.location.href);
  const langParam = url.searchParams.get('lang');
  if (langParam) {
    setLanguage(langParam as Locale.Languages);
  }
}
