import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <Navbar location={location} navigate={navigate} />
      <Outlet />
      <Footer location={location} navigate={navigate} />
    </div>
  );
};

export default Layout;
