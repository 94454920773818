import logo from '../../../assets/imgs/nikolica_logo_white.svg';
import en from '../../../locales/languages/en';
import { ABOUT, PROPERTY_NAME } from '../../../utils/Constants';

const About = ({ lang }: { lang: typeof en }) => {
  return (
    <div id={ABOUT} className='about'>
      <div className='about_container container'>
        <div className='about_container_logo'>
          <div
            data-aos='fade-up'
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <img src={logo} alt={PROPERTY_NAME + 'logo'} />
          </div>
        </div>
        <div className='about_container_text'>
          <h2 data-aos='fade-up' className='title_underline_light'>
            {lang.about.title}
          </h2>
          <p data-aos='fade-up' data-aos-delay='50'>
            {lang.about.p1}
          </p>
          <p data-aos='fade-up' data-aos-delay='100'>
            {lang.about.p2}
          </p>
          <p data-aos='fade-up' data-aos-delay='150'>
            {lang.about.p3}
          </p>
          <p data-aos='fade-up' data-aos-delay='200'>
            {lang.about.p4}
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
