import en from '../../../locales/languages/en';
import peljesacImg from '../../../assets/imgs/home/discover/peljesac.webp';
import dingacImg from '../../../assets/imgs/home/discover/dingac.webp';
import potomjeImg from '../../../assets/imgs/home/discover/potomje.webp';
import orebicImg from '../../../assets/imgs/home/discover/orebic.webp';

interface Feature {
  description: JSX.Element;
  imgPath: string;
  link?: string;
}

const DiscoverList = ({ lang }: { lang: typeof en }) => {
  const PELJESAC: Feature = {
    description: (
      <>
        <h2 className='title_underline_complementary_small'>Pelješac</h2>
        <p>{lang.discover.peljesac.p1}</p>
        <p>{lang.discover.peljesac.p2}</p>
        <p>{lang.discover.peljesac.p3}</p>
        <p>{lang.discover.peljesac.p4}</p>
      </>
    ),
    imgPath: peljesacImg,
  };

  const DINGAC: Feature = {
    description: (
      <>
        <h2 className='title_underline_complementary_small'>Dingač</h2>
        <p>{lang.discover.dingac.p1}</p>
        <p>{lang.discover.dingac.p2}</p>
        <p>{lang.discover.dingac.p3}</p>
        <p>{lang.discover.dingac.p4}</p>
      </>
    ),
    imgPath: dingacImg,
  };

  const POTOMJE: Feature = {
    description: (
      <>
        <h2 className='title_underline_complementary_small'>Potomje</h2>
        <p>{lang.discover.potomje.p1}</p>
        <p>{lang.discover.potomje.p2}</p>
        <p>{lang.discover.potomje.p3}</p>
      </>
    ),
    imgPath: potomjeImg,
  };

  const OREBIĆ: Feature = {
    description: (
      <>
        <h2 className='title_underline_complementary_small'>Orebić</h2>
        <p>{lang.discover.orebic.p1}</p>
        <p>{lang.discover.orebic.p2}</p>
        <p>{lang.discover.orebic.p3}</p>
      </>
    ),
    imgPath: orebicImg,
  };

  return [PELJESAC, DINGAC, POTOMJE, OREBIĆ];
};

export default DiscoverList;
