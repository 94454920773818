import en from '../../locales/languages/en';
import { GALLERY } from '../../utils/Constants';
import Slideshow from '../slideshow/Slideshow';

const Gallery = ({
  lang,
  imageList,
}: {
  lang: typeof en;
  imageList: string[];
}) => {
  return (
    <div id={GALLERY} className='gallery'>
      <div className='gallery_container container'>
        <h2 data-aos='fade-up'>{lang.gallery.title}</h2>
        <div
          className='gallery_container_imgs'
          data-aos='fade-up'
          data-aos-delay='50'
        >
          <Slideshow slideshowImages={imageList} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
