import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import DiscoverList from './DiscoverList';
import {
  arrowLeft,
  arrowRight,
  DISCOVER,
  separatorColoredUp,
} from '../../../utils/Constants';
import Variables from '../../../style/variables.scss';
import en from '../../../locales/languages/en';

const Discover = ({ lang }: { lang: typeof en }) => {
  const transitionSpeed: number = 500;

  const discoverList = DiscoverList({ lang });

  const imgRef = useRef<Slider>(null);
  const textRef = useRef<Slider>(null);

  const [activeSlide, setActiveSlide] = useState<number>(0);

  const next = () => {
    imgRef.current?.slickNext();
    textRef.current?.slickNext();
  };

  const prev = () => {
    imgRef.current?.slickPrev();
    textRef.current?.slickPrev();
  };

  useEffect(() => {
    setActiveSlide(0);
  }, []);

  const slickImage = {
    adaptiveHeight: false,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slickText = {
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 870,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      setActiveSlide(next);
    },
  };

  return (
    <div id={DISCOVER} className='discover'>
      <div className='discover_container container'>
        <h2 className='text_center' data-aos='fade-up'>
          {lang.discover.title}
        </h2>
        <div className='discover_container_content'>
          <div
            className='discover_container_content_text'
            data-aos='fade-up'
            data-aos-delay='50'
          >
            <Slider ref={textRef} {...slickText}>
              {discoverList.map((discover) => (
                <>{discover.description}</>
              ))}
            </Slider>
            <div className='discover_container_content_buttons'>
              <div className='opacity_transition_low' onClick={() => prev()}>
                {arrowLeft(Variables.secondaryColor)}
              </div>
              <div className='opacity_transition_low' onClick={() => next()}>
                {arrowRight(Variables.secondaryColor)}
              </div>
              <div className='discover_container_content_buttons_counter'>
                {activeSlide + 1} / {discoverList.length}
              </div>
            </div>
          </div>
          <div className='discover_container_content_img'>
            <Slider ref={imgRef} {...slickImage}>
              {discoverList.map((discover) => (
                <img
                  data-aos='fade-up'
                  data-aos-delay='150'
                  src={discover.imgPath}
                  alt={`${DISCOVER} ${
                    discoverList[discoverList.indexOf(discover)]
                  }`}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
      {separatorColoredUp(Variables.secondaryColor)}
    </div>
  );
};

export default Discover;
