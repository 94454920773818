import { ReactElement } from 'react';
import en from '../../../locales/languages/en';
import Variables from '../../../style/variables.scss';
import {
  separatorColoredDown,
  SERVICES,
} from '../../../utils/Constants';
import servicesList from './servicesList';

type Service = {
  viewBox: string;
  path: ReactElement;
  description: string;
};

const FacilitiesAndServices = ({ lang }: { lang: typeof en }) => {
  const iconsHeight: number = 24;
  const iconsWidth: number = 24;

  return (
    <div id={SERVICES} className='services'>
      {separatorColoredDown(Variables.secondaryColor)}
      <div className='container'>
        <h2 className='text_center' data-aos='fade-up'>
          {lang.services.title}
        </h2>
        <div className='services_container'>
          {servicesList({ lang }).map((x: Service, index) => (
            <div data-aos='fade-up' data-aos-delay={`${(index + 1) * 50}`}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox={x.viewBox}
                height={iconsHeight}
                width={iconsWidth}
                fill={Variables.secondaryColor}
              >
                {x.path}
              </svg>
              {x.description}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FacilitiesAndServices;
