import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import en from '../../locales/languages/en';
import { LOCATION } from '../../utils/Constants';
import { useCallback } from 'react';

const mapProps = {
  cordinations: {
    lat: 42.923446,
    lng: 17.345922,
  },
  zoom: 18,
};

const mapStyles = [
  {
    featureType: 'all',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
];

const LocationMap = ({ lang }: { lang: typeof en }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAP_KEY),
  });

  const onMarkerClick = useCallback(() => {
    window.open('https://maps.app.goo.gl/vTwukCWjUKu5okCx5', '_blank'); // Opens link in a new tab
  }, []);

  return (
    <div id={LOCATION}>
      <div className='location container'>
        <h2 data-aos='fade-up'>{lang.location.title}</h2>
        <div data-aos='fade' data-aos-delay='100'>
          {isLoaded && (
            <GoogleMap
              id='location_container'
              center={mapProps.cordinations}
              zoom={mapProps.zoom}
              options={{
                styles: mapStyles,
                mapTypeId: 'satellite',
                mapTypeControl: false,
                zoomControl: false,
                fullscreenControl: false,
                keyboardShortcuts: false,
                streetViewControl: false,
              }}
            >
              <Marker
                position={mapProps.cordinations}
                clickable={true}
                title={'click me'}
                onClick={onMarkerClick}
              />
            </GoogleMap>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationMap;
