import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { close } from '../../utils/Constants';

const Modal = ({
  id,
  isOpen,
  onClose,
  children,
  primaryColor,
  secondaryColor,
}: {
  id: string;
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
  primaryColor: string;
  secondaryColor: string;
}) => {
  if (isOpen) {
    document.body.classList.add('lock_scroll');
  } else {
    return null;
  }

  return ReactDOM.createPortal(
    <div id={id} className='modal' style={{ background: `${primaryColor}` }}>
      <div
        className='modal_close'
        onClick={() => {
          document.body.classList.remove('lock_scroll');
          onClose(false);
        }}
      >
        {close(secondaryColor)}
      </div>
      {children}
    </div>,
    document.body
  );
};

export default Modal;
