import en from './en';

const hr: typeof en = {
  title: 'Apartmani Nikolica',
  home: 'početna',
  showMore: 'Prikaži Više',
  bookNow: 'Rezerviraj',
  about: {
    title: 'Apartmani Nikolica',
    p1: 'U srcu poznate hrvatske vinske regije, Nikolica Apartmani pružaju gostima brojne mogućnosti za uživanje u svemu što Pelješac nudi.',
    p2: 'Smješteni uz more, ovi apartmani savršena su polazna točka za ljubitelje aktivnosti na otvorenom koji žele plivati, planinariti, biciklirati i istraživati okolinu.',
    p3: 'U svakom apartmanu gosti će pronaći moderno uređenje, kupaonicu s tušem, potpuno opremljenu kuhinju, klima uređaj, SAT TV te terasu s prekrasnim pogledom.',
    p4: 'Dodatne pogodnosti uključuju parkirno mjesto, roštilj, kao i vanjski bazen s ležaljkama.',
  },
  services: {
    title: 'Pogodnosti',
    parking: 'Parkiralište na licu mjesta',
    privacy: 'Vlasnici ne žive na ovoj lokaciji',
    beach: 'Najbliža plaža: 20 m',
    wifi: 'Besplatan WiFi',
    restaurant: 'Najbliži restoran: 40 m',
    towels: 'Zamjena ručnika: jednom tjedno',
    tv: 'Satelitska TV',
    traffic: 'Najbliža prometnica: 4 km',
    smoking: 'Pušenje nije dopušteno',
    ac: 'Klima uređaj',
    atm: 'Najbliži bankomat: 4 km',
    pets: 'Kućni ljubimci nisu dopušteni',
  },
  accommodation: {
    title: 'Apartmani',
    p1: 'Smješten odmah iznad plaže, ovo mirno imanje očarava goste prekrasnim pogledom na Jadransko more i okolnu mediteransku vegetaciju koja pruža mir i tišinu za opuštajući odmor uz more.',
  },
  discover: {
    title: 'Otkrijte Pelješac',
    peljesac: {
      p1: `Poluotok Pelješac nalazi se na pola puta između dva jadranska bisera, Splita i Dubrovnika. Drugi najveći poluotok u Dalmaciji uživa u prekrasnoj mediteranskoj klimi, što ga čini idealnim mjestom za vinograde i proizvodnju vina.`,
      p2: `Pelješac je također poznat po svojim solanama, o kojima su stoljećima ovisili mnogi, uključujući Dubrovnik i njegovu slavnu Republiku. Drevni obrambeni zid upotpunjuje trilogiju peljeških simbola. Mještani vole nazivati svoj poluotok "prirodom u njenom iskonskom obliku."`,
      p3: `Rivijera Pelješca je možda najtoplije područje hrvatskog priobalja, aromatični vrt ispunjen bademima, orasima, smokvama, šipkom, citrusima i kivijem. Čisto more, koje čini Pelješac privlačnim turističkim odredištem, pronalazi put do trpeza posjetitelja ovog područja.`,
      p4: `Među brojnim vrstama riba, rakova i školjki, posebna pohvala pripada kamenicama. Mali Ston je poznat po uzgoju i pripremi ovog delikatesa kojem, osim izvanrednog okusa i nutritivnih vrijednosti, pripisuju i afrodizijačka svojstva.`,
    },
    dingac: {
      p1: `Dingač predstavlja idealno mjesto za sve one koji uživaju u prirodnim ljepotama Mediterana. To je omiljeno odredište za obiteljske odmore posjetitelja iz cijele Europe.`,
      p2: `Dingač i njegova okolica poznati su po svojoj vinskoj tradiciji uzgoja grožđa za Dingač, jedno od najboljih crnih vina u Hrvatskoj, koje se uzgaja na strmim padinama od 45 stupnjeva s istim nazivom. Ovo izvrsno vino ima snažan okus zahvaljujući toploj klimi s puno sunca (2.800 sati godišnje). Zbog toga, ali i kako bi uživali u miru, mnogi turisti dolaze u Dingač Borak.`,
      p3: `Trgovina, pošta i benzinska postaja udaljeni su 3 km, dok je more udaljeno 10 do 20 metara od privatnih kuća. Uživajte na suncu na prekrasnim pješčanim i šljunčanim plažama, kao i na stjenovitim kupalištima ispred privatnih kuća.`,
      p4: `Pelješac je fascinantna destinacija za poludnevne ili cjelodnevne izlete. Ima slikovite male gradove i sela, bogato kulturno i povijesno naslijeđe, prekrasno obrađene vinograde (posebno one smještene na strmim padinama Dingača) i brojne spomenike ljudskom trudu i vještini.`,
    },
    potomje: {
      p1: `Potomje je staro selo smješteno u središnjem dijelu Pelješca, na 300 metara nadmorske visine, 2,5 km od mora i 15 km jugoistočno od Orebića.`,
      p2: `Radi lakšeg pristupa jugu, izgrađen je tunel dug 400 metara iz Potomja, koji je danas izvanredna turistička atrakcija.`,
      p3: `Smješteno u zapadnom dijelu vinogradarskih područja Dingač, Potomje je središte proizvodnje najboljih crnih vina u Hrvatskoj (Dingač, Postup i Plavac).`,
    },
    orebic: {
      p1: `Orebić je poznato turističko središte poluotoka Pelješca, slikovitih 20 km vožnje od Dingača, koje nudi restorane, barove, teniske terene i diskoteke.`,
      p2: `Za sve one koji preferiraju aktivniji odmor, Pelješac pruža raznovrsne rekreativne mogućnosti na moru: od plivanja, surfanja, skijanja na vodi, veslanja i jedrenja, do ronjenja u istraživanju raznolike morske flore i faune te arheoloških lokaliteta.`,
      p3: `Ostale rekreativne mogućnosti također su raznolike: pješačke staze kroz prirodu daleko od automobila, biciklizam i planinarenje, osobito na najvišem vrhu Pelješca - Sveti Ilija (961 m).`,
    },
  },
  gallery: {
    title: 'Galerija',
  },
  location: {
    title: 'Lokacija',
  },
  footer: {
    title: 'Kontaktirajte nas',
    nikolica: 'Apartmani Nikolica',
    radovic: 'obitelj Radović',
  },
};

export default hr;
