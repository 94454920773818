import heroImage from '../../assets/imgs/home/hero.webp';
import About from './about/About';
import { CapitalizeFirstLetter } from '../../utils/CapitalizeFirstLetter';
import { useEffect } from 'react';
import { BOOK_NOW_LINK, HOME, PROPERTY_NAME } from '../../utils/Constants';
import { getLocales } from '../../locales/Locale';
import LocationMap from '../../components/locationMap/LocationMap';
import Gallery from '../../components/gallery/Gallery';
import Services from './services/Services';
import Discover from './discover/Discover';
import Accommodation from './accommodation/Accommodation';

const gallery = require.context('../../assets/imgs/home/gallery', true);
const galleryList = gallery.keys().map((image: any) => gallery(image));

const Home = () => {
  const { lang } = getLocales();
  let header: HTMLElement | null = document.getElementById('nav_container');

  useEffect(() => {
    document.title = `${CapitalizeFirstLetter(lang.title)}`;
    document.body.classList.remove('lock_scroll');
  }, []);

  setTimeout(() => {
    if (header !== null) {
      header.style.top = '0';
    }
  }, 1200);
  return (
    <div id={HOME} className='home body_wrapper'>
      <div className='home_container_hero'>
        <img src={heroImage} alt='hero' />
        <div className='home_container_hero_overlay'></div>
        <div className='home_container_hero_button button'>
          <a
            href={BOOK_NOW_LINK}
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            <div>{lang.bookNow.toLocaleUpperCase()}</div>
          </a>
        </div>
      </div>
      <h1 hidden>{PROPERTY_NAME}</h1>
      <About lang={lang} />
      <Services lang={lang} />
      <Accommodation lang={lang} />
      <Discover lang={lang} />
      <Gallery lang={lang} imageList={galleryList} />
      <LocationMap lang={lang} />
    </div>
  );
};

export default Home;
