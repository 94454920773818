import { useState } from 'react';
import Slider from 'react-slick';
import AccommodationList from './AccommodationList';
import Variables from '../../../style/variables.scss';
import { ACCOMMODATION, arrowLeft, arrowRight } from '../../../utils/Constants';
import en from '../../../locales/languages/en';

const Accommodation = ({ lang }: { lang: typeof en }) => {
  const [sliderTexts, setSliderTexts] = useState<Slider>();
  const [sliderImgs, setSliderImgs] = useState<Slider>();

  const slickSettingsTitles = {
    initialSlide: 5,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    infinite: true,
    focusOnSelect: true,
    slidesToShow: 3,
    arrows: true,
    speed: 500,
    slidesToScroll: 1,
    asNavFor: sliderImgs,
    prevArrow: (
      <div className='opacity_transition_low'>
        {arrowLeft(Variables.secondaryColor)}
      </div>
    ),
    nextArrow: (
      <div className='opacity_transition_low'>
        {arrowRight(Variables.secondaryColor)}
      </div>
    ),
  };

  const slickSettingsImgs = {
    initialSlide: 5,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    speed: 500,
    slidesToScroll: 1,
    asNavFor: sliderTexts,
  };

  return (
    <div id={ACCOMMODATION} className='accommodation'>
      <div className='accommodation_container container'>
        <h2 className='text_center' data-aos='fade-up'>
          {lang.accommodation.title}
        </h2>
        <p data-aos='fade-up' data-aos-delay='50'>
          {lang.accommodation.p1}
        </p>
        <div data-aos='fade-up' data-aos-delay='100'>
          <Slider
            {...slickSettingsTitles}
            ref={(slider) =>
              slider ? setSliderTexts(slider) : setSliderTexts(undefined)
            }
            className='accommodation_container_slick_title'
          >
            {AccommodationList.map((x) => (
              <div className='accommodation_container_slick_title_container'>
                {x.accommodationTitle}
              </div>
            ))}
          </Slider>
        </div>
        <Slider
          {...slickSettingsImgs}
          ref={(slider) =>
            slider ? setSliderImgs(slider) : setSliderImgs(undefined)
          }
          className='accommodation_container_slick'
        >
          {AccommodationList.map((x) => (
            <div
              className='accommodation_container_slick_container'
              data-aos='fade-up'
              data-aos-delay='150'
            >
              <img
                src={x.accommodationImage}
                alt={x.accommodationTitle}
                className='accommodation_container_slick_container_image'
              />
              <div
                data-index={`${AccommodationList.indexOf(x)}`}
                className='accommodation_container_slick_container_box'
              >
                <div className='accommodation_container_slick_container_box_title title_underline_complementary_small'>
                  {x.accommodationTitle}
                </div>
                <div className='accommodation_container_slick_container_box_info'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' height={32} width={32} fill={Variables.secondaryColor}>
                    <path d='M335.5 4l288 160c15.4 8.6 21 28.1 12.4 43.5s-28.1 21-43.5 12.4L320 68.6 47.5 220c-15.4 8.6-34.9 3-43.5-12.4s-3-34.9 12.4-43.5L304.5 4c9.7-5.4 21.4-5.4 31.1 0zM320 160a40 40 0 1 1 0 80 40 40 0 1 1 0-80zM144 256a40 40 0 1 1 0 80 40 40 0 1 1 0-80zm312 40a40 40 0 1 1 80 0 40 40 0 1 1 -80 0zM226.9 491.4L200 441.5l0 38.5c0 17.7-14.3 32-32 32l-48 0c-17.7 0-32-14.3-32-32l0-38.5L61.1 491.4c-6.3 11.7-20.8 16-32.5 9.8s-16-20.8-9.8-32.5l37.9-70.3c15.3-28.5 45.1-46.3 77.5-46.3l19.5 0c16.3 0 31.9 4.5 45.4 12.6l33.6-62.3c15.3-28.5 45.1-46.3 77.5-46.3l19.5 0c32.4 0 62.1 17.8 77.5 46.3l33.6 62.3c13.5-8.1 29.1-12.6 45.4-12.6l19.5 0c32.4 0 62.1 17.8 77.5 46.3l37.9 70.3c6.3 11.7 1.9 26.2-9.8 32.5s-26.2 1.9-32.5-9.8L552 441.5l0 38.5c0 17.7-14.3 32-32 32l-48 0c-17.7 0-32-14.3-32-32l0-38.5-26.9 49.9c-6.3 11.7-20.8 16-32.5 9.8s-16-20.8-9.8-32.5l36.3-67.5c-1.7-1.7-3.2-3.6-4.3-5.8L376 345.5l0 54.5c0 17.7-14.3 32-32 32l-48 0c-17.7 0-32-14.3-32-32l0-54.5-26.9 49.9c-1.2 2.2-2.6 4.1-4.3 5.8l36.3 67.5c6.3 11.7 1.9 26.2-9.8 32.5s-26.2 1.9-32.5-9.8z' />
                  </svg>
                  {x.accommodationCapacity}
                </div>
                <div className='accommodation_container_slick_container_box_links'>
                  <a
                    href={x.accommodationLink}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div>BOOK NOW</div>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Accommodation;
