import superiorSuiteImg from '../../../assets/imgs/home/accommodation/superior_suite.webp';
import superiorImg from '../../../assets/imgs/home/accommodation/superior.webp';
import premiumImg from '../../../assets/imgs/home/accommodation/premium.webp';
import luxuryStudioImg from '../../../assets/imgs/home/accommodation/luxury_studio.webp';
import luxuryImg from '../../../assets/imgs/home/accommodation/luxury.webp';
import deluxeImg from '../../../assets/imgs/home/accommodation/deluxe.webp';
import deluxeComfortImg from '../../../assets/imgs/home/accommodation/deluxe_comfort.webp';
import deluxeApartmentImg from '../../../assets/imgs/home/accommodation/deluxe_apartmant.webp';
import deluxeSuiteImg from '../../../assets/imgs/home/accommodation/deluxe_suite.webp';

interface Accommodation {
  accommodationTitle: string;
  accommodationCapacity: string;
  accommodationImage: string;
  accommodationLink: string;
}

const AccommodationList: Array<Accommodation> = [
  {
    accommodationTitle: 'Superior Suite',
    accommodationCapacity: '6+2',
    accommodationImage: superiorSuiteImg,
    accommodationLink:
      'https://booking.hotelstouch.com/b/booking?code=Sx9zQWmpmpk5&fdUnitTypeCode=xBL0yBdW',
  },
  {
    accommodationTitle: 'Superior',
    accommodationCapacity: '4+2',
    accommodationImage: superiorImg,
    accommodationLink:
      'https://booking.hotelstouch.com/b/booking?code=Sx9zQWmpmpk5&fdUnitTypeCode=NZL8MdEG',
  },
  {
    accommodationTitle: 'Premium',
    accommodationCapacity: '6+2',
    accommodationImage: premiumImg,
    accommodationLink:
      'https://booking.hotelstouch.com/b/booking?code=Sx9zQWmpmpk5&fdUnitTypeCode=2XqbnjqG',
  },
  {
    accommodationTitle: 'Luxury Studio',
    accommodationCapacity: '2',
    accommodationImage: luxuryStudioImg,
    accommodationLink:
      'https://booking.hotelstouch.com/b/booking?code=Sx9zQWmpmpk5&fdUnitTypeCode=mrqykvd1',
  },
  {
    accommodationTitle: 'Luxury',
    accommodationCapacity: '3',
    accommodationImage: luxuryImg,
    accommodationLink:
      'https://booking.hotelstouch.com/b/booking?code=Sx9zQWmpmpk5&fdUnitTypeCode=oedNpNql',
  },
  {
    accommodationTitle: 'Deluxe',
    accommodationCapacity: '4',
    accommodationImage: deluxeImg,
    accommodationLink:
      'https://booking.hotelstouch.com/b/booking?code=Sx9zQWmpmpk5&fdUnitTypeCode=bNdz8q5E',
  },
  {
    accommodationTitle: 'Deluxe Comfort',
    accommodationCapacity: '4',
    accommodationImage: deluxeComfortImg,
    accommodationLink:
      'https://booking.hotelstouch.com/b/booking?code=Sx9zQWmpmpk5&fdUnitTypeCode=BDq1wdwX',
  },
  {
    accommodationTitle: 'Deluxe Apartment',
    accommodationCapacity: '4',
    accommodationImage: deluxeApartmentImg,
    accommodationLink:
      'https://booking.hotelstouch.com/b/booking?code=Sx9zQWmpmpk5&fdUnitTypeCode=NzqVblqA',
  },
  {
    accommodationTitle: 'Deluxe Suite',
    accommodationCapacity: '4',
    accommodationImage: deluxeSuiteImg,
    accommodationLink:
      'https://booking.hotelstouch.com/b/booking?code=Sx9zQWmpmpk5&fdUnitTypeCode=lvdQz9kV',
  },
];

export default AccommodationList;
